<template>
    <div class="dashboard">
        <div class="wrapper">
            <div class="h1">Заблокированные пользователи</div>
            <div class="auto-scroll-horizontal">
                <b-table
                    :busy="isBusy"
                    :fields="fields"
                    :items="unbanProvider"
                    class="mb-0"
                    :current-page="currentPage"
                    :per-page="perPage"
                    empty-text="Нет доступных запросов"
                    show-empty
                    ref="unbanTable"
                >
                    <template #cell(user.lastName)="data">
                        <div class="user-td">
                            <img v-if="data.item.user.avatarPath" :src="data.item.user.avatarPath" alt="">
                            <div class="user-name">
                                {{data.item.user.lastName}}
                                {{data.item.user.firstName}}
                                {{data.item.user.patroName}}
                            </div>
                        </div>
                    </template>

                    <template #cell(user.email.address)="data">
                        {{data.item.user.email.address}}
                    </template>

                    <template #cell(updatedAt)="data">
                        {{ parseDate(data.item.updatedAt) }}
                    </template>

                    <template #cell(unban)="data">
                        <b-button type="button" :disabled="!data.item.user.ban" variant="primary submit" @click="handleUnban(data.item)">разблокировать</b-button>
                    </template>

                    <template #cell(delete)="data">
                        <button class="btn-crl" @click="handleDeleteUnban(data.item)">
                            <b-icon icon="x-circle-fill"></b-icon>
                        </button>
                    </template>

                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #empty="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>

                    <template #emptyfiltered="scope">
                        <div class="text-center my-2">
                            {{ scope.emptyText }}
                        </div>
                    </template>
                </b-table>
            </div>
        </div>

        <div v-if="totalRequests > perPage" class="pt-3">
            <b-pagination first-number last-number :total-rows="totalRequests" :per-page="perPage" v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import { errorsAlertApi } from '@/helpers/errorsAlertApi'
    import parseDate from '@/mixins/parseDate'

    export default {
        name: "unban",
        mixins: [parseDate],
        methods: {
            ...mapActions([
                'getUserUnban',
                'deleteUserUnban',
                'setUserStatus'
            ]),
            unbanProvider(ctx){
                const data = {
                    page: ctx.currentPage
                };

                if(ctx.sortBy){
                    data.sortBy = ctx.sortBy;
                    data.sort = ctx.sortDesc ? 'desc' : 'asc'
                }

                return this.getUserUnban(data).then(unbansApi => {
                    this.unbans = unbansApi.results;
                    this.totalRequests = unbansApi.totalCount;
                    return this.unbans
                })
                    .catch(error => {
                        errorsAlertApi(error);
                        return []
                    });
            },
            handleUnban(unban){
                this.$swal({
                    icon: 'warning',
                    html: `Вы действительно хотите разблокировать пользователя <br/><strong>${unban.user.lastName} ${unban.user.firstName} ${unban.user.patroName}</strong>?`,
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        const data = {
                            userId: unban.userId,
                            ban: false,
                            language: unban.user.languageCode
                        };
                        this.setUserStatus(data).then(userApi => {
                            for (let i = 0, len = this.unbans.length; i < len; i++) {
                                if(this.unbans[i]._id === unban._id){
                                    Object.assign(this.unbans[i].user, userApi);
                                    break;
                                }
                            }
                        })
                    }
                })
            },
            handleDeleteUnban(unban){
                this.$swal({
                    icon: 'warning',
                    html: `Вы действительно хотите удалить запрос разблокировать пользователя <br/><strong>${unban.user.lastName} ${unban.user.firstName} ${unban.user.patroName}</strong>?`,
                    showCancelButton: true,
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.deleteUserUnban(unban._id).then(() => {
                            this.unbans = this.unbans.filter(item => item._id !== unban._id);
                            this.$refs.unbanTable.refresh();
                        })
                    }
                })
            }
        },
        data(){
            return{
                unbans: [],
                isBusy: false,
                fields: [
                    {
                        key: 'user.lastName',
                        label: 'ФИО',
                        sortable: true,
                        tdClass: 'w-25'
                    }, {
                        key: 'user.email.address',
                        label: 'E-mail',
                        sortable: true
                    }, {
                        key: 'text',
                        label: 'Описание',
                        sortable: false,
                    }, {
                        key: 'updatedAt',
                        label: 'Дата',
                        sortable: true,
                    }, {
                        key: 'unban',
                        label: '',
                    }, {
                        key: 'delete',
                        label: '',
                    }
                ],
                currentPage: 1,
                perPage: 10,
                totalRequests: 0,
            }
        }
    }
</script>

<style lang="scss" scoped>
    .user{
        &-td {
            display: flex;
            align-items: center;
            min-height: 48px;
            img {
                width: 48px;
                height: 48px;
                margin-right: 10px;
                border-radius: 50%;
                overflow: hidden;
                object-fit: cover;
                flex: none;
            }
        }
    }
</style>